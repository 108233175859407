.upper-layer {
    min-height: 50vh;
    width: 100vw;
    /* border:3px solid red; */
    display: flex;
}

.upper-layer .upper-layer-left {
    min-height: 50vh;
    width: 100%;
    /* border: 1px solid black; */
    display: flex;
    margin: 0 10px;
}

.upper-layer-left .upper-layer-tabs-area {
    min-height: 50vh;
    width: 70%;
    /* border:1px solid pink; */
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
}

.upper-layer-tabs-area-top {
    height: 50%;
    width: 100%;
    /* border-bottom: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.upper-layer-tabs-area-bottom {
    height: 50%;
    width: 100%;
    /* border-bottom: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-content {
    height: 16.5vh;
    width: 50%;
    margin-right: 5px;
    /* border:1px solid black; */
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.tab-playlist {
    height: 16.5vh !important;
    width: 50% !important;
    /*  border:'1px solid black', */
    margin-right: 5px;
    background: #ffffff !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    position: relative !important;
}

.tab-folder {
    height: 16.5vh !important;
    width: 50% !important;
    /*  border:'1px solid black', */
    /* margin-left:5px; */
    background: #ffffff !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    position: relative !important;
}

.tab-screen {
    height: 16.5vh !important;
    width: 50% !important;
    /*  border:'1px solid black', */
    /* margin-left:5px; */
    background: #ffffff !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    position: relative !important;
}

.upper-layer-left .upper-layer-views-area {
    min-height: 50vh;
    width: 30%;
    /* border:1px solid pink; */
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upper-layer-right {
    min-height: 50vh;
    width: 100%;
    /* border: 6px solid purple; */
    display: flex;
    /* flex-wrap: wrap; */
}

.upper-layer-right-screen {
    min-height: 50vh;
    width: 50%;
    /* border:1px solid yellow; */
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upper-layer-right-map {
    min-height: 50vh;
    width: 50%;
    /* border:1px solid brown; */
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-layer {
    min-height: 50vh;
    max-width: 100vw;
    /* border:2px solid darkcyan; */
    display: flex;
}

.bottom-layer-wrapper {
    min-height: 50vh;
    width: 100%;
    display: flex;
    margin: 0 10px;
}

.bottom-layer-left {
    min-height: 50vh;
    width: 50%;
    /* border:1px solid pink; */
    display: flex;
    margin-right: 5px;
}

.bottom-layer-right {
    min-height: 50vh;
    width: 50%;
    /* border:1px solid pink; */
    display: flex;
    margin-left: 5px;
}

@media only screen and (max-width:768px) {
    .upper-layer {
        flex-direction: column;
        /* background-color: blueviolet; */

    }

    .upper-layer-left {
        width: 90%;
    }

    .upper-layer-tabs-area {
        /* border:1px solid yellow; */
        width: 50%;
    }

    .upper-layer-views-area {
        /* border:1px solid green; */
        width: 50%;
    }

    .upper-layer-right {
        /* border:3px solid black; */
        width: 95%;
    }

    .upper-layer-right-screen {
        /* border:1px solid green; */
        width: 50%;
    }

    .upper-layer-right-map {
        /* border:1px solid red; */
        width: 50%;
        /* display: none; */
    }

    .bottom-layer {
        /* border:1px solid green; */
        height: 30vh;
    }

    .bottom-layer-wrapper {
        flex-direction: column;
        /* border:1px solid black;  */
    }

    .bottom-layer-left {
        width: 90vw;
        /* border:1px solid pink; */
    }

    .bottom-layer-right {
        width: 90vw;
        /* border:1px solid red; */
    }

    /* .upper-layer-views-area-md-device .views-counter-md-device{
        height:50%;
        width: 50%;
    } */
}

@media only screen and (max-width:480px) {
    .upper-layer {
        flex-direction: column;
        /* min-height: 50vh; */
    }

    .upper-layer-left,
    .upper-layer-right {
        flex-wrap: wrap;
        margin: 0;
        /* border:1px solid red; */
        /* min-height: 50vh; */
    }

    .upper-layer-left .upper-layer-views-area {
        width: 90%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border:1px solid black; */
    }

    .upper-layer-left .upper-layer-tabs-area {
        width: 90%;
        margin: 0;
        /* border:1px solid black; */
    }

    .upper-layer-right .upper-layer-right-screen {
        margin-left: 10px;
        margin-top: 10px;
        /* border:1px solid pink; */
        min-height: 20vh;
        /* height:90%; */
    }

    .upper-layer-right .upper-layer-right-screen .screen-mobile-statistics {
        height: 80%;
    }

    .upper-layer-right .upper-layer-right-map {
        margin: -30px 10px;
        /* border:1px solid yellowgreen; */
    }

    .upper-layer-left {
        flex-direction: column;
    }

    .upper-layer-right .upper-layer-right-screen,
    .upper-layer-right-map {
        width: 90%;
        /* margin:0; */
    }

    .bottom-layer .bottom-layer-wrapper {
        flex-direction: column;
        margin: 0;
        width: 90%;
    }

    .bottom-layer-wrapper .bottom-layer-left {
        min-height: 25vh;
        width: 90vw;
        margin-left: 10px;
    }

    .bottom-layer-wrapper .bottom-layer-left img {
        height: 100%;
        width: 100%;
        margin: 0;
    }

    .bottom-layer-wrapper .bottom-layer-right {
        margin-left: -10px;
        /* margin:0 10px; */
    }

    .bottom-layer {
        /* border:1px solid red; */
        width: 90vw;
        margin-top: -25px;
    }

    .img-resize-mobile {
        height: 100%;
        width: 100%;
    }
}

/* screen 480px */

/* 320px */
@media only screen and (max-width:320px) {
    .upper-layer-left .upper-layer-tabs-area {
        width: 90%;
        margin: 0;
        /* border:1px solid green; */
    }

    .upper-layer-tabs-area-top,
    .upper-layer-tabs-area-bottom {
        /* border:1px solid red; */
        flex-direction: column;
        height: 100%;
        /* width:100%; */
    }

    .upper-layer-tabs-area .upper-layer-tabs-area-top .tab-content {
        width: 100%;
        height: 100%;
        /* border:1px solid pink; */
    }

    .upper-layer-tabs-area .upper-layer-tabs-area-top .tab-folder {
        margin-top: 15px;
        width: 100% !important;
        height: 100% !important;
        /* border:1px solid pink; */
    }

    .tab-playlist {
        margin-top: 15px;
        height: 100% !important;
        width: 100% !important;
        /* border:1px solid pink; */
    }

    .upper-layer-tabs-area .upper-layer-tabs-area-bottom .tab-screen {
        width: 100% !important;
        height: 100% !important;
        margin-top: 15px;
        /* height:50%; */
        /* border:2px solid pink; */
    }

    .upper-layer-left .upper-layer-views-area {
        margin-top: 10px !important;
    }

    /* .customBtnClass{
        height:10px !important;
        width:30px !important;
    } */
}

/* 320px end */