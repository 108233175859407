@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Open+Sans:wght@300;400&display=swap');

@import '~simplebar-react/dist/simplebar.min.css';

body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  font-family: 'Open Sans';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player-canva>div,
.player-canva iframe {
  width: 100% !important;
  height: 100% !important;
}