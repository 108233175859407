.minLoader {
    height: 80px;
    width: 80px;
    border: 15px solid whitesmoke;
    border-radius: 50%;
    border-top: 15px solid #551ffa;
    animation: loader 1s infinite linear;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}